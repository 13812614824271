<template>
  <div class="sidebar--header">
    <img :src="developer_image" class="" :alt="name" />
    <div class="developer--name text-center my-3">
      <a :href="developer_web" target="_blank">
        <strong>{{ name }}</strong></a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    developer_image: {
      type: String,
    },
    developer_web: {
      type: String,
    },
    name: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar-header {
  img {
    height: auto;
    width: 80%;
    margin: auto;
    display: block;
  }
}
</style>
